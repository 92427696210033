<template>
  <div class="content">
    <!-- <PageHeader title="Configurações do Omni Business" /> -->
    <PageHeader title="Configurações do Chat" />
    <section class="page-content container-fluid">
      <div class="static qt-block-ui" v-if="loading"></div>
      <template v-else>
        <div class="row cards">
          <div class="col-lg-6">
            <div class="card">
              <h3 class="card-header">Tempo máximo de resposta do agente</h3>
              <div class="card-body block-el">
                <div class="row">
                  <div class="col-md-2">
                    <toggle-button v-model="enableMaxAgentResponseTime" />
                  </div>
                  <div class="col-md-10">
                    <p>Caso o agente não responda dentro do período definido, o sistema finalizará o atendimento automaticamente.</p>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light py-1" v-if="enableMaxAgentResponseTime">
                <div class="row">
                  <div class="col-md-7 p-3">
                    Tempo máximo de resposta do agente
                  </div>
                  <div class="col-md-5 extras form-inline justify-content-md-end">
                    <input type="time" class="form-control mb-2 mr-2" max="24:00" v-model="form.maxAgentResponseTime">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <h3 class="card-header">Tempo máximo de resposta do cliente</h3>
              <div class="card-body block-el">
                <div class="row">
                  <div class="col-md-2">
                    <toggle-button v-model="enableMaxClientResponseTime" />
                  </div>
                  <div class="col-md-10">
                    <p>Caso o cliente não responda dentro do período definido, o sistema finalizará o atendimento automaticamente.</p>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light py-1" v-if="enableMaxClientResponseTime">
                <div class="row">
                  <div class="col-md-7 p-3">
                    Tempo máximo de resposta do cliente
                  </div>
                  <div class="col-md-5 extras form-inline justify-content-md-end">
                    <input type="time" class="form-control mb-2 mr-2" max="24:00" v-model="form.maxClientResponseTime">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <h3 class="card-header">Solicitar tabulação ao finalizar</h3>
              <div class="card-body block-el">
                <div class="row">
                  <div class="col-md-2">
                    <toggle-button v-model="form.tabulation" />
                  </div>
                  <div class="col-md-10">
                    <p>
                      Ao finalizar um atendimento o agente deverá obrigatóriamente selecionar uma tabulação antes de fechar
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <h3 class="card-header">Tempo de inatividade</h3>
              <div class="card-body block-el">
                <div class="row">
                  <div class="col-md-2">
                    <toggle-button v-model="enableMaxDowntimeTime" />
                  </div>
                  <div class="col-md-10">
                    <p>Tempo de inatividade.</p>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light py-1" v-if="enableMaxDowntimeTime">
                <div class="row">
                  <div class="col-md-7 p-3">
                    Tempo de inatividade
                  </div>
                  <div class="col-md-5 extras form-inline justify-content-md-end">
                    <input type="time" class="form-control mb-2 mr-2" max="24:00" v-model="form.maxDowntime">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <!-- queue -->
            <div class="card">
              <h3 class="card-header">Notificação de fila</h3>
              <div class="card-body block-el">
                <div class="row">
                  <div class="col-md-2">
                    <toggle-button v-model="enableQueueNumber" />
                  </div>
                  <div class="col-md-10">
                    <textarea
                      class="form-control"
                      v-model="form.message_before_notification_queue"
                      :disabled="!enableQueueNumber"></textarea>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light py-1" v-if="enableQueueNumber">
                <div class="row">
                  <div class="col-md-9 p-3">
                    A partir desse numero de fila será notificada a posição de atendimento
                  </div>
                  <div class="col-md-3 extras time form-inline justify-content-md-end">
                    <input
                      ref="queueNumberRef"
                      type="tel"
                      class="form-control mb-2 mr-2"
                      min="0" max="5000"
                      v-model="form.queue_number_notification"
                      @input="clearInvalids()">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <!-- -->
            <div class="card">
              <h3 class="card-header">Notificação antes do fechamento automatico</h3>
              <div class="card-body block-el">
                <div class="row">
                  <div class="col-md-2">
                    <toggle-button v-model="enableAutoCloseMessage" />
                  </div>
                  <div class="col-md-10">
                    <textarea
                      class="form-control"
                      v-model="form.autoclose_predecessor_message"
                      :disabled="!enableAutoCloseMessage"></textarea>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light py-1" v-if="enableAutoCloseMessage">
                <div class="row">
                  <div class="col-md-7 p-3">
                    Tempo em minutos antes do fechamento
                  </div>
                  <div class="col-md-5 extras form-inline justify-content-md-end">
                    <!-- {{form.timeOfPredecessorMessage}} -->
                    <input
                      type="time"
                      class="form-control
                      mb-2 mr-2"
                      max="01:00"
                      v-model="form.timeOfPredecessorMessage"
                      @change="checkAutoCloseMessageTime()"
                      @blur="checkAutoCloseMessageTime()">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card">
              <h3 class="card-header">Layout da fila</h3>
              <div class="card-body block-el">
                <div class="row">
                  <div class="col-3">
                    <div class="mb-4 row">
                      <div class="mb-1 col-6">Fila</div>
                      <div class="col-6">
                        <toggle-button
                          :sync="true"
                          v-model="layoutTypeQueue"
                          @change="updateLayoutType('queue')" />
                      </div>
                    </div>
                    <div class="mb-4 row">
                      <div class="mb-1 col-6">Lista</div>
                      <div class="col-6">
                        <toggle-button
                          :sync="true"
                          v-model="layoutTypeList"
                          @change="updateLayoutType('list')" />
                      </div>
                    </div>
                  </div>
                  <div class="col-8" v-if="!form.queued_enabled">
                    <div class="mb-1">
                      Opção de aceitar/recusar atendimento atribuído
                      <toggle-button
                        :sync="true"
                        v-model="form.acceptance_option" />
                    </div>
                    <textarea
                      class="form-control"
                      placeholder="Mensagem quando o cliente é associado a um agente"
                      v-model="form.agent_association_message"></textarea>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light py-1" v-if="layoutTypeList">
                <div class="row">
                  <div class="col-md-7 p-3">
                    Tempo máximo na lista (minutos)
                  </div>
                  <div class="col-md-5 extras form-inline justify-content-md-end">
                    <input
                      type="time"
                      class="form-control mb-2 mr-2"
                      max="24:00"
                      v-model="maxListingTime"
                      @change="checkMaxListingTime()"
                      @blur="checkMaxListingTime()">
                  </div>
                </div>
              </div>
            </div>
            <!-- -->
          </div>
          <div class="col-lg-6">
            <div class="card">
              <h3 class="card-header">Mensagem de espera para o cliente</h3>
              <div class="card-body block-el">
                <!-- <label for="message_customer_wait" class="form-label">
                  Mensagem de espera para o cliente
                </label> -->
                <textarea
                  id="message_customer_wait"
                  class="form-control"
                  v-model="form.message_customer_wait"></textarea>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <!-- -->
            <div class="card">
              <h3 class="card-header">Solicitação de dados no primeiro contato do cliente</h3>
              <div class="card-body block-el">
                <div class="row">
                  <div class="col-md-10">
                    <div class="form-group">
                      <label for="link_client_type">Tipo de dados</label>
                      <select class="form-control custom-messages-type" v-model="form.link_client_type" id="link_client_type">
                        <option value="BOTH">Telefone e E-mail</option>
                        <option value="PHONE">Telefone</option>
                        <option value="EMAIL">E-mail</option>
                        <option value="NONE">Nenhum</option>
                      </select>
                    </div>
                    <div class="row">
                      <div class="col-12 col-md-6 custom-messages">
                        <!-- nome -->
                        <template v-if="form.link_client_type !== 'NONE'">
                          <div class="form-group">
                            <label for="welcome_message">
                              Mensagem de boas-vindas
                            </label>
                            <input type="text" class="form-control" v-model="form.welcome_message" id="welcome_message">
                          </div>
                          <div class="form-group">
                            <label for="name_request_text">
                              Mensagem solicitando nome
                            </label>
                            <input type="text" class="form-control" v-model="form.name_request_text" id="name_request_text">
                          </div>
                          <div class="form-group">
                            <label for="error_format_name">
                              Mensagem de erro customizada no formato do nome
                            </label>
                            <input type="text" class="form-control" v-model="form.error_format_name" id="error_format_name">
                          </div>
                        </template>

                        <!-- telefone -->
                        <template v-if="form.link_client_type === 'BOTH' || form.link_client_type === 'PHONE'">
                          <div class="form-group">
                            <label for="phone_request_text">Mensagem solicitando telefone</label>
                            <input type="text" class="form-control" v-model="form.phone_request_text" id="phone_request_text">
                          </div>
                          <div class="form-group">
                            <label for="error_format_phone">Mensagem de erro customizada no formato do telefone</label>
                            <input type="text" class="form-control" v-model="form.error_format_phone" id="error_format_phone">
                          </div>
                        </template>

                        <!-- email -->
                        <template v-if="form.link_client_type === 'BOTH' || form.link_client_type === 'EMAIL'">
                          <div class="form-group">
                            <label for="email_request_text">Mensagem solicitando email</label>
                            <input type="text" class="form-control" v-model="form.email_request_text" id="email_request_text">
                          </div>
                          <div class="form-group">
                            <label for="error_format_email">Mensagem de erro customizada no formato do email</label>
                            <input type="text" class="form-control" v-model="form.error_format_email" id="error_format_email">
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- -->
        <div class="d-flex flex-row-reverse">
          <button class="btn btn-primary" @click="save">Salvar</button>
        </div>
      </template>
      <!--
      <div class="card">
        <h3 class="card-header">Ocultar contatos para os agentes</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              Com esta opção ativada, apenas os gerentes e administradores poderão visualizar todos os contatos e os agentes visualizarão os contatos que já mantiveram alguma conversa.
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">Mensagem de agradecimento</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              Com esta opção ativada, o sistema enviará para o cliente uma mensagem agradecendo pelo contato realizado, sempre que finalizar o atendimento.
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">Retenção dos atendimentos</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              Com esta opção ativada, os atendimentos só ficarão disponíveis na fila de atendimento caso o Bot não resolva o problema do cliente com o total de erros configurados.
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">Nome do agente na mensagem</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              Com esta opção ativada, a mensagem enviada para o contato conterá o nome do agente no início. Ex: Nome_Agente: Mensagem.
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">Tabulação obrigatória</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              Com esta opção ativada, todo atendimento que for finalizado pelos agentes, deverão ser tabulados.
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">Transferência de atendimento</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              Com esta opção ativada, o sistema enviará uma mensagem automaticamente para os contatos informando que o atendimento foi transferido para outro agente.
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">Envio de arquivos</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              Com esta opção ativada, o sistema permitirá que os agentes enviem arquivos.
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">Recebimento de arquivos</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              Com esta opção ativada, o sistema fará o processamento dos arquivos recebidos.
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">Botões de respostas rápidas</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              Com esta opção ativada, o cliente terá a possibilidade de escolher departamentos através de botões nos canais Messenger, Widget e Telegram Bot.
            </div>
          </div>
        </div>
      </div>
      -->
    </section>
  </div>
</template>

<script>
// import Emojis from '@/components/Emojis.vue';
import PageHeader from '@/components/PageHeader.vue';
import { ToggleButton } from 'vue-js-toggle-button';
import axios from '@/plugins/axios';

export default {
  name: 'System',
  components: {
    // InfiniteLoading,
    // Emojis,
    PageHeader,
    ToggleButton,
  },
  data() {
    return {
      loading: true,
      enableMaxAgentResponseTime: false,
      enableMaxClientResponseTime: false,
      enableMaxDowntimeTime: false,
      enableQueueNumber: false,
      enableAutoCloseMessage: false,
      lastQueuedEnabled: false,
      layoutTypeQueue: false,
      layoutTypeList: false,

      maxListingTime: 0,

      form: {
        maxAgentResponseTime: '24:00',
        maxClientResponseTime: '24:00',
        tabulation: false,
        maxDowntime: '24:00',
        queue_number_notification: 0,
        message_before_notification_queue: '',
        time_of_predecessor_message: 0,
        autoclose_predecessor_message: '',

        queued_enabled: true,
        message_customer_wait: '',

        agent_association_message: '',

        link_client_type: 'BOTH',

        welcome_message: '',
        name_request_text: '',
        error_format_name: '',

        email_request_text: '',
        error_format_email: '',

        phone_request_text: '',
        error_format_phone: '',

        max_listing_time: 0,
        acceptance_option: false,
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  mounted() {
    axios().get('webchat/system').then((response) => {
      this.loading = false;

      console.log('SAVE!!', 'loader', response.data);

      if (response.data.live.max_time_agent !== '86400') this.enableMaxAgentResponseTime = true;

      if (response.data.live.max_time_client !== '86400') this.enableMaxClientResponseTime = true;

      if (response.data.middleware.downtime !== '0') this.enableMaxDowntimeTime = true;

      let hA = Math.floor(response.data.live.max_time_agent / 60);
      let hC = Math.floor(response.data.live.max_time_client / 60);
      let hD = Math.floor(response.data.middleware.downtime / 60);

      let mA = response.data.live.max_time_agent % 60;
      let mC = response.data.live.max_time_client % 60;
      let mD = response.data.middleware.downtime % 60;

      if (hA < 10) hA = `0${hA}`;
      if (hC < 10) hC = `0${hC}`;
      if (hD < 10) hD = `0${hD}`;

      if (mA < 10) mA = `0${mA}`;
      if (mC < 10) mC = `0${mC}`;
      if (mD < 10) mD = `0${mD}`;

      const maxAgentResponseTime = `${hA}:${mA}`;
      const maxClientResponseTime = `${hC}:${mC}`;
      const maxDowntime = `${hD}:${mD}`;

      this.form.maxAgentResponseTime = maxAgentResponseTime;
      this.form.maxClientResponseTime = maxClientResponseTime;
      this.form.tabulation = response.data.live.must_tabulate_enabled;
      this.form.maxDowntime = maxDowntime;

      const numberQueue = response.data.live.queue_number_notification;
      this.enableQueueNumber = numberQueue > 0;

      this.form.queue_number_notification = numberQueue > 0 ? numberQueue : 0;
      this.form.message_before_notification_queue = response.data.live.message_before_notification_queue;

      const timeAutoClose = response.data.live.time_of_predecessor_message;
      this.enableAutoCloseMessage = timeAutoClose > 0;

      let hT = Math.floor(timeAutoClose / 60);
      let mT = timeAutoClose % 60;

      if (hT < 10) hT = `0${hT}`;
      if (mT < 10) mT = `0${mT}`;

      this.form.timeOfPredecessorMessage = `${hT}:${mT}`;

      this.form.time_of_predecessor_message = timeAutoClose > 0 ? timeAutoClose : 0;
      this.form.autoclose_predecessor_message = response.data.live.autoclose_predecessor_message;

      const maxListingTime = response.data.live.max_listing_time;

      let hMLT = Math.floor(maxListingTime / 60);
      let mMLT = maxListingTime % 60;

      // console.log('maxListingTime', { maxListingTime, hMLT, mMLT });

      if (hMLT < 10) hMLT = `0${hMLT}`;
      if (mMLT < 10) mMLT = `0${mMLT}`;

      this.form.max_listing_time = maxListingTime;
      this.maxListingTime = `${hMLT}:${mMLT}`;

      this.form.acceptance_option = response.data.live.acceptance_option ?? true;

      this.lastQueuedEnabled = response.data.live.queued_enabled ?? true;
      this.form.queued_enabled = this.lastQueuedEnabled;

      this.layoutTypeQueue = this.lastQueuedEnabled;
      this.layoutTypeList = !this.lastQueuedEnabled;

      this.form.message_customer_wait = response.data.live.message_customer_wait ?? '';
      this.form.agent_association_message = response.data.live.agent_association_message ?? '';

      this.form.link_client_type = response.data.middleware.link_client_type;

      this.form.welcome_message = response.data.middleware.welcome_message;
      this.form.name_request_text = response.data.middleware.name_request_text;
      this.form.error_format_name = response.data.middleware.error_format_name;

      this.form.email_request_text = response.data.middleware.email_request_text;
      this.form.error_format_email = response.data.middleware.error_format_email;

      this.form.phone_request_text = response.data.middleware.phone_request_text;
      this.form.error_format_phone = response.data.middleware.error_format_phone;

      console.log('SYSTEM', this.form, response.data);
    }).catch((error) => {
      this.loading = false;
      console.error(error);
    });
  },
  destroyed() {
  },
  methods: {
    updateLayoutType(type) {
      if (type === 'queue') {
        this.layoutTypeList = !this.layoutTypeQueue;
      } else if (type === 'list') {
        this.layoutTypeQueue = !this.layoutTypeList;
      }

      console.log('this.layoutTypeQueue', this.layoutTypeQueue);
      console.log('this.layoutTypeList', this.layoutTypeList);

      this.form.queued_enabled = this.layoutTypeQueue;
    },
    checkAutoCloseMessageTime() {
      const placeholder = this.form.timeOfPredecessorMessage;
      const [hourA, minA] = placeholder.split(':').map((value) => parseInt(value, 10));
      const time = `${(hourA * 60) + minA}`;

      this.form.time_of_predecessor_message = time;

      if (time > 60 || time < 0) {
        this.form.timeOfPredecessorMessage = '00:00';
        this.form.time_of_predecessor_message = '0';
      }
    },
    checkMaxListingTime() {
      const placeholder = this.maxListingTime;
      const [hourA, minA] = placeholder.split(':').map((value) => parseInt(value, 10));
      const time = `${(hourA * 60) + minA}`;

      this.form.max_listing_time = time;
    },
    clearInvalids() {
      const ref = this.$refs.queueNumberRef;
      const value = parseInt(ref.value, 10);

      if (!value || Number.isNaN(value)) ref.value = '';
    },
    save() {
      this.loading = true;

      if (!this.enableQueueNumber) {
        this.form.queue_number_notification = '0';
      } else {
        this.enableQueueNumber = this.form.queue_number_notification > 0;
      }

      console.log('system update', this.form, this.enableQueueNumber);

      const [hourA, minA] = this.form.maxAgentResponseTime.split(':').map((value) => parseInt(value, 10));
      const [hourC, minC] = this.form.maxClientResponseTime.split(':').map((value) => parseInt(value, 10));
      const [hourD, minD] = this.form.maxDowntime.split(':').map((value) => parseInt(value, 10));

      axios().put('webchat/system', {
        max_time_agent: {
          type: 'M',
          value: !this.enableMaxAgentResponseTime ? '86400' : `${(hourA * 60) + minA}`,
        },
        max_time_client: {
          type: 'M',
          value: !this.enableMaxClientResponseTime ? '86400' : `${(hourC * 60) + minC}`,
        },
        must_tabulate_enabled: this.form.tabulation,
        max_downtime: !this.enableMaxDowntimeTime ? '0' : `${(hourD * 60) + minD}`,
        queue_number_notification: this.form.queue_number_notification,
        message_before_notification_queue: this.form.message_before_notification_queue,
        time_of_predecessor_message: !this.enableAutoCloseMessage ? '0' : this.form.time_of_predecessor_message,
        autoclose_predecessor_message: this.form.autoclose_predecessor_message,
        queued_enabled: this.form.queued_enabled,
        message_customer_wait: this.form.message_customer_wait,
        agent_association_message: this.form.agent_association_message,
        link_client_type: this.form.link_client_type,
        welcome_message: this.form.welcome_message,
        name_request_text: this.form.name_request_text,
        error_format_name: this.form.error_format_name,
        email_request_text: this.form.email_request_text,
        error_format_email: this.form.error_format_email,
        phone_request_text: this.form.phone_request_text,
        error_format_phone: this.form.error_format_phone,
        max_listing_time: this.form.max_listing_time,
        acceptance_option: this.form.acceptance_option,
      }).then((response) => {
        this.loading = false;
        this.reconnect();
        console.log(response);
      }).catch((error) => {
        this.loading = false;
        console.error(error);
      });
    },
    reconnect() {
      if (this.lastQueuedEnabled !== this.form.queued_enabled) {
        this.lastQueuedEnabled = this.form.queued_enabled;
        this.$live.reconnect();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.qt-block-ui {
  min-height: 200px;
}

.custom-messages-type {
  max-width: 200px;
}

.custom-messages-type, .custom-messages > :nth-child(2n) {
  margin-bottom: 3.2rem;
}

.cards {
  .card {
    min-height: 240px;
  }

  .card-footer {
    font-size: 80%;
  }

  input[type="tel"] {
    text-align: right;
    width: 100%;
  }
}
</style>
